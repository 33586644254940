

























































































































































import Vue from 'vue';
import artificial from './artificial.vue';
import quotaTable from '../../components/quotaTable.vue';
import materialMachineTable from '../../../materialMachineLibrary/component/materialMachineTable.vue';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
import { materialList } from '@/api/materialMachineLibrary';
import { MaterialType } from '@/interface/materialMachineLibrary';
import { getNumber } from '@/utils';

const columns = [
  {
    title: '编码',
    key: 'bdCode',
    width: '60px',
  },
  {
    title: '名称',
    key: 'bdName',
    width: '150px',
  },
  {
    title: '单位',
    key: 'unit',
  },
  {
    title: '人工费',
    key: 'rfee',
  },
  {
    key: 'cfee',
  },
  {
    title: '机械费',
    key: 'jfee',
  },
  {
    title: '管理费及利润',
    key: 'managerFee',
    width: '60px',
  },
  {
    title: '定额综合单价',
    key: 'price',
  },
  {
    title: '替换后综合单价',
    key: 'replacePrice',
  },
];
const materialColumns = [
  {
    title: '名称',
    key: 'materialName',
  },
  {
    title: '规格型号',
    key: 'specification',
  },
  {
    title: '计量单位',
    key: 'unit',
  },
  {
    title: '定额价',
    key: 'dePrice',
  },
  {
    key: 'marketPrice',
  },
  {
    title: '企业价',
    key: 'companyPrice',
  },
];
export default Vue.extend({
  name: 'costCombination',
  components: { artificial, quotaTable, materialMachineTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    actionRecord: {
      type: Object,
      default: () => null,
    },
    addCost: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isRecommendShow: true,
      selectedList: [] as QyStlBillDeType[],
      selectedKeys: [] as string[],
      columns,
      materialColumns,
      isRecommendVisible: true,
      materialData: [] as MaterialType[],
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        console.log('visible', this.actionRecord);
        let list = this.actionRecord?.deList?.map((item) => {
          item.replacePrice = item.price!;
          return item;
        });
        this.selectedList = !list ? [] : JSON.parse(JSON.stringify(list));
        this.getMaterialDetail(false);
      }
    },
  },
  methods: {
    /**
     * 替换时的类名
     */
    setRowClassName(record) {
      return record.customClassName || '';
    },
    getMaterialDetail(isUpdate = true) {
      if (!this.selectedList.length) {
        this.materialData = [];
        return;
      }
      const { agencyCode } = this.$store.getters.userInfo;
      materialList(this.selectedList, agencyCode).then(({ status, result }) => {
        if (status === 200) {
          const { intelligentRCJDetails, quotaRCJList } = result;
          this.materialData = intelligentRCJDetails;
          if (isUpdate) {
            this.selectedList = quotaRCJList;
          }
        }
      });
    },
    replacePrice(key, record) {
      if (!record[key]) return;
      const { materialCode, dePrice } = record;
      this.selectedList.forEach((item, index) => {
        for (let rcjItem of item.adsQycbRcjModelList || []) {
          if (rcjItem.materialCode === materialCode) {
            rcjItem.replacePrice = rcjItem.replacePrice || 0;
            const replacePrice = (record[key] - dePrice) * rcjItem.resQty;
            item.cfee = Number(
              (item.cfee - rcjItem.replacePrice + replacePrice).toFixed(2)
            );
            item.replacePrice = item.replacePrice || 0;
            item.replacePrice = Number(
              (
                item.replacePrice! -
                rcjItem.replacePrice +
                replacePrice
              ).toFixed(2)
            );
            rcjItem.marketPrice = record[key];
            rcjItem.replacePrice = replacePrice;
            item.customClassName = 'twinkle';
            this.$set(this.selectedList, index, item);
            const timer = setTimeout(() => {
              item.customClassName = '';
              this.$set(this.selectedList, index, item);
              clearTimeout(timer);
            }, 2000);
          }
        }
      });
    },
    cancel() {
      this.selectedList = [];
      this.$emit('update:visible', false);
    },
    /**
     * 智能应用选择数据回调
     */
    useSelectedData(selectedRows: QyStlBillDeType[]) {
      // const filterData = this.filterSelectedData(selectedRows);
      selectedRows = selectedRows.map((item, index) => {
        item.sequenceNbr = (index + new Date().getTime()).toString(10);
        item.replacePrice = item.price!;
        item.adsQycbRcjModelList = item.adsQycbRcjModelList?.map((rcjItem) => {
          rcjItem.marketPrice = rcjItem.dePrice;
          rcjItem.initMarketPrice = rcjItem.dePrice;
          return rcjItem;
        });
        return item;
      });
      this.selectedList = [...this.selectedList, ...selectedRows];
      this.getMaterialDetail();
    },
    quotaMountMaterial(selectedList: QyStlBillDeType[], quotaRCJList) {
      selectedList.forEach((item) => {
        const rcjList = JSON.parse(JSON.stringify(quotaRCJList[item.id]));
        if (rcjList && rcjList.length) {
          item.adsQycbRcjModelList = rcjList;
        }
        return item;
      });
      return selectedList;
    },
    save() {
      if (!this.selectedList.length) {
        return this.$message.warning('定额不能为空');
      }
      this.$emit('saveCallback', this.selectedList);
      this.cancel();
    },
    actionHandler({ record, key, index }) {
      let keyIndex = this.selectedKeys.indexOf(key);
      this.selectedList.splice(index, 1);
      this.selectedKeys.splice(keyIndex, 1);
      if (!this.selectedKeys.length) {
        this.materialData = [];
      }
      this.getMaterialDetail();
    },
  },
});
