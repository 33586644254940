













































































































import Vue from 'vue';
import quotaTable from '../../components/quotaTable.vue';
import { TableActionType, QyStlBillDeType } from '@/interface/costQuotaLibrary';
import {
  libraryFullListByTree,
  likeByBdCodeAndName,
  queryListByBdCodeAndName,
  queryListByClassify,
  typeList,
} from '@/api/costQuotaLibrary/costLibrary';
import _ from 'lodash';
const columns = [
  {
    title: '编码',
    key: 'bdCode',
    width: '10%',
  },
  {
    title: '名称',
    key: 'bdName',
    width: '20%',
    align: 'left',
  },
  {
    title: '单位',
    key: 'unit',
  },
  {
    title: '人工费',
    key: 'rfee',
    width: '15%',
  },
  {
    title: '材料费',
    key: 'cfee',
    width: '15%',
  },
  {
    title: '机械费',
    key: 'jfee',
    width: '15%',
  },
  {
    title: '管理费及利润',
    key: 'managerFee',
    width: '10%',
  },
  {
    title: '定额综合单价',
    key: 'price',
    width: '10%',
  },
];
const actionList = [
  {
    type: TableActionType.SELECT,
    name: '选择',
  },
];
export default Vue.extend({
  name: 'artificial',
  components: { quotaTable },
  props: {},
  data() {
    return {
      treeData: [] as Array<Record<string, any>>,
      columns,
      actionList,
      tableData: [],
      expandedKeys: [] as string[],
      queryForm: {
        bdName: undefined,
        libraryName: '',
        classifyLevel1: '',
        classifyLevel2: '',
        classifyLevel3: '',
        classifyLevel4: '',
      },
      loading: true,
      quotaVolumeList: [], // 定额册列表
      selectList: [], // 输入内容搜索查询前十条
    };
  },
  mounted() {
    this.typeList();
  },
  methods: {
    actionHandler({ record, key, index, e }) {
      console.log({ record, key, index });
      e.stopPropagation();
      this.$emit('useSelectedData', [_.cloneDeep(record)]);
    },
    selectedData({ record, index, e }) {
      this.$emit('useSelectedData', [record]);
    },
    preOperation(e) {
      e.stopPropagation();
    },
    onSearch(item: any) {
      this.queryForm.bdName = item.bdName;
      if (!this.queryForm.bdName) {
        this.libraryFullListByTree();
        return;
      }
      queryListByBdCodeAndName({
        bdName: this.queryForm.bdName,
        libraryName: this.queryForm.libraryName,
      }).then((res: any) => {
        if (res.status === 200 && res.result) {
          this.treeData = this.getInitData(res.result);
          this.selectList = [];
        }
      });
    },
    libraryFullListByTree() {
      libraryFullListByTree(this.queryForm.libraryName).then((res: any) => {
        if (res.status === 200 && res.result) {
          this.treeData = this.getInitData(res.result);
          this.loading = false;
          console.log('--------', this.treeData);
        }
      });
    },
    getInitData(tree: Array<Record<string, any>>) {
      return tree.map((item) => {
        item.key = item.name + Math.ceil(Math.random() * 10000 + 1);
        if (!item.path || item.path.split('/').length < 2) {
          this.expandedKeys.push(item.key);
        }
        item.children = item.children?.length
          ? this.getInitData(
              item.children.map((n) => ({
                ...n,
                key: n.name + Math.ceil(Math.random() * 10000 + 1),
                path: (item.path ? item.path : item.name) + '/' + n.name,
              }))
            )
          : null;
        return item;
      });
    },
    async selectNode(
      selectedKeys: string[],
      {
        node,
      }: {
        node: { dataRef: Record<string, any>; expanded: boolean };
      }
    ) {
      const data = node.dataRef;
      if (
        data.path &&
        (!data.children || !data.children.length) &&
        !data.childrenList
      ) {
        this.getSearchForm();
        let pathList = node.dataRef.path.split('/');
        this.queryForm.libraryName = pathList[0];
        pathList.forEach((item, index) => {
          if (index === 0) return;
          this.queryForm[`classifyLevel${index}`] = item;
        });
        const cacheData = this.getCacheData();
        let childrenList: QyStlBillDeType[] = [];
        if (cacheData) {
          childrenList = cacheData;
        } else {
          data.quotaLoading = true;
          const queryResult = await queryListByClassify(this.queryForm);
          data.quotaLoading = false;
          childrenList = queryResult.result;
          this.setCacheData(childrenList);
        }
        data.childrenList = childrenList;
        if (childrenList.length > 0) data.visible = true;
      }
      if (data.childrenList && !data.children) {
        data.visible = true;
      }
      const nodeKey = node.dataRef.key as string;
      if (!node.expanded) {
        this.expandedKeys.push(nodeKey);
      } else {
        data.visible = false;
        this.expandedKeys = this.expandedKeys.filter((key: string) => {
          return key !== nodeKey;
        });
      }
    },
    async expandNode(
      selectedKeys: string[],
      {
        node,
      }: {
        node: { dataRef: Record<string, any>; expanded: boolean };
      }
    ) {
      const data = node.dataRef;
      const nodeKey = node.dataRef.name as string;
      if (!node.expanded) {
        this.expandedKeys.push(nodeKey);
        if (
          data.path &&
          (!data.children || !data.children.length) &&
          !data.childrenList
        ) {
          this.getSearchForm();
          let pathList = node.dataRef.path.split('/');
          this.queryForm.libraryName = pathList[0];
          pathList.forEach((item, index) => {
            if (index === 0) return;
            this.queryForm[`classifyLevel${index}`] = item;
          });
          const cacheData = this.getCacheData();
          let childrenList: QyStlBillDeType[] = [];
          if (cacheData) {
            childrenList = cacheData;
          } else {
            data.quotaLoading = true;
            const queryResult = await queryListByClassify(this.queryForm);
            data.quotaLoading = false;
            childrenList = queryResult.result;
            this.setCacheData(childrenList);
          }
          data.childrenList = childrenList;
          if (childrenList.length > 0) data.visible = true;
        }
        if (data.childrenList && !data.children) {
          data.visible = true;
        }
        this.$forceUpdate();
      } else {
        data.visible = false;
        this.expandedKeys = this.expandedKeys.filter((key: string) => {
          return key !== nodeKey;
        });
        this.$forceUpdate();
      }
    },
    /**
     * 先从缓存中读取定额数据
     */
    getCacheData() {
      let dataAll = sessionStorage.getItem('ysf_adsStlDeByClassify');
      if (dataAll) {
        const data = JSON.parse(dataAll);
        const key = JSON.stringify(this.queryForm);
        return data[key];
      }
      return null;
    },
    /**
     * 设置缓存
     */
    setCacheData(cacheData) {
      let dataAll = sessionStorage.getItem('ysf_adsStlDeByClassify');
      if (dataAll) {
        let data = JSON.parse(dataAll);
        const key = JSON.stringify(this.queryForm);
        data[key] = cacheData;
        sessionStorage.setItem('ysf_adsStlDeByClassify', JSON.stringify(data));
      }
    },
    getSearchForm() {
      Object.assign(this.queryForm, {
        libraryName: '',
        classifyLevel1: '',
        classifyLevel2: '',
        classifyLevel3: '',
        classifyLevel4: '',
      });
    },
    typeList() {
      typeList().then((res: any) => {
        console.log('typeList', res);
        if (res.status === 200 && res.result) {
          this.quotaVolumeList = res.result;
          this.queryForm.libraryName = res.result[0];
          this.libraryFullListByTree();
        }
      });
    },
    handleSearch(value) {
      console.log('value', value);
      this.queryForm.bdName = value;
      // this.queryForm.libraryName = this.quotaVolumeList[0];
      this.likeByBdCodeAndName();
    },
    handleSelectChange() {
      this.likeByBdCodeAndName();
    },
    likeByBdCodeAndName() {
      if (!this.queryForm.bdName) {
        this.selectList = [];
        this.libraryFullListByTree();
        return;
      }
      let apiData = {
        bdName: this.queryForm.bdName,
        libraryName: this.queryForm.libraryName,
      };
      likeByBdCodeAndName(apiData).then((res: any) => {
        console.log('likeByBdCodeAndName');
        this.selectList = res.result;
      });
    },
    handleChange(value) {
      console.log('value', value);
      this.queryForm.libraryName = value;
      this.libraryFullListByTree();
    },
  },
});
